import React from 'react'
import { Link } from 'react-router-dom'

import { HomeThumbnail } from '../../../constants/HomeThumbnail'

import './ProjectsHome.css'

const ProjectsHome = () => {
  return (
    <div>
        <div className='projects_home-thumb'>
        {HomeThumbnail.map((d) => (
          <div className='projects_home-thumb-item' key={d.id}>
            <Link to={d.link}><img
              src={d.image}
              alt="Project Thumbnail"
            /></Link>
            <div className='projects_home-thumb-item-divp'>
              <p className='projects_home-thumb-title-p'>{d.title}
              <br/><p className='projects_home-thumb-cat-p'>{d.cat.join(', ')}</p></p>
              <Link to={d.link}><p className='projects_home-thumb-link-p'>Read →</p></Link>
            </div>
          </div>
        ))}
        </div>    
    </div>
  )
}

export default ProjectsHome