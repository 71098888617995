import AboutImg from '../assets/about-me.jpg'
import LogoBlk from '../assets/logo-black.png'
import ProjectSoon from '../assets/project-soon.jpg'
import ProjectHSoon from '../assets/project-header-soon.jpg'

export default {
    ProjectSoon,
    ProjectHSoon,
    AboutImg,
    LogoBlk
}