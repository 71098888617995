import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'

import Images from '../../constants/Images'

import './Navbar.css';

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
    <nav className='app_navbar'>
        <div className='app_navbar-logo'>
            <NavLink to='/'><img src={Images.LogoBlk} alt="Randy saputra logo" /></NavLink>
        </div>

        <ul className='app_navbar-links'>
            <li><NavLink to='/' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Home</NavLink></li>
            <li><NavLink to='/work'>Work</NavLink></li>
            <li><NavLink to='/about'>About</NavLink></li>
        </ul>

        <div className='app_navbar-smallscreen'>
            <FaBars className='app_navbar-smallscreen-menu' onClick={() => setToggleMenu(true)}/>

            { toggleMenu && (
            <div className='app_navbar-smallscreen-overlay'>
                <FaTimes className='app_navbar-smallscreen-close' onClick={() => setToggleMenu(false)}/>
                
                <ul className='app_navbar-smallscreen-links'>
                    <li><NavLink to='/' className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                    onClick={() => setToggleMenu(false)}>Home</NavLink></li>
                    <li><NavLink to='/work' onClick={() => setToggleMenu(false)}>Work</NavLink></li>
                    <li><NavLink to='/about' onClick={() => setToggleMenu(false)}>About</NavLink></li>
                </ul>
            </div>
            )}

        </div>
    </nav>
  )
}

export default Navbar