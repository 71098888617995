import React, { useEffect } from 'react'

import { About } from '../../container/'

const AboutMe = () => {
  useEffect(() => {
    document.title = "Randy | About Me";  
  }, []);

  return (
    <div>
        <About />
    </div>
  )
}

export default AboutMe