import React from 'react'
import { Link } from 'react-router-dom'

import './BackToProjects.css'

const BackToProjects = () => {
  return (
    <div className='back-to-projects'>
        <p className='back-to-projects-p'><Link to='/work'>← Back to projects</Link></p>
    </div>
  )
}

export default BackToProjects