import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import ProjectsFilter from './ProjectsFilter';
import { AllThumbnail, WebThumbnail, MobileThumbnail, FilterProjects } from '../../../constants/WorkThumbnail'

import './ProjectsWork.css';

const ProjectsWork = () => {
  const [filterData, setFilter] = useState("all");
  const [workThumb, setData] = useState([]);

  useEffect(() => {
    switch (filterData) {
      case "all":
        setData(AllThumbnail);
        break;
      case "web":
        setData(WebThumbnail);
        break;
      case "mobile":
        setData(MobileThumbnail);
        break;
      default:
        setData(AllThumbnail);
    }
  }, [filterData]);

  return (
    <div className='projects_work'>
      <p className='projects_work-head'>Projects</p><br/>
      <p className='projects_work-p'>What tags mean on each project:
      <br/>Project status, type of project, project platform</p>
      <ul className='projects_work-filter'>
          {FilterProjects.map((item) => (
          <ProjectsFilter
              title={item.title}
              active={filterData === item.id}
              setFilter={setFilter}
              id={item.id}
          />
          ))}
      </ul>
      
      <div className='projects_work-thumb'>
        {workThumb.map((d) => (
          <div className='projects_work-thumb-item'>
            <Link to={d.link}><img
              src={d.image}
              alt="Project Thumbnail"
            /></Link>
            <div className='projects_work-thumb-item-divp'>
              <p className='projects_work-thumb-title-p'>{d.title}
              <br/><p className='projects_work-thumb-cat-p'>{d.cat.join(', ')}</p></p>
              <Link to={d.link}><p className='projects_work-thumb-link-p'>Read →</p></Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjectsWork