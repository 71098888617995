import React, { useEffect } from 'react'

import { BackToProjects } from '../../../../components/'
import Images from '../../../../constants/Images'

import './Project1.css'

const Project1 = () => {
  useEffect(() => {
    document.title = "Randy | Project 1";
  }, []);

  return (
    <div className='project1_content'>
        <img src={Images.ProjectHSoon} alt="Project image header" />
        <div className='project1_content-summary'>
          <h2>Project 1</h2>
          <p>Jan 2020 - Mar 2020</p>
        </div>
        <BackToProjects />
    </div>
  )
}

export default Project1