import React from 'react'
import { FaRegEnvelope, FaInstagram, FaTwitter } from 'react-icons/fa'

import Images from '../../constants/Images'

import './About.css'

const About = () => {
  return (
    <header className='cont_about'>
        <img src={Images.AboutImg} alt="About Me" />
        <div>
            <h1 className='cont_about-content-head'>Hi there!</h1><br/>
            <p className='cont_about-content-p'>My name is Randy. Currently I am self employed with some side projects and trying to
            learn myself into tech career field. I love working on projects that are going to make people's lives easier and happy.
            <br/><br/>
            I&apos;ve always been into art since I was a kid. The power of art and design that we face in our everyday lives is so powerful.
            There is a saying that says &quot;Good design goes unnoticed&quot; and I believe so because you&apos;ll only complain when its bad.
            <br/><br/>
            I studied at School of Information Systems - Binus University where I completed my Bachelor of Information Systems in 2016.
            <br/>As time goes on, I&apos;ve found myself learning more about design, UI/UX, front-end technology, mobile apps, and even nocode platforms.
            </p><br/><br/>

            <div className='cont_about-content-social'>
            <a href='mailto:hi.randysaputra@gmail.com'><span><FaRegEnvelope /></span></a>
            <a href='https://instagram.com/ransptr' target="_blank"><span><FaInstagram /></span></a>
            <a href='https://twitter.com/ransptr' target="_blank"><span><FaTwitter /></span></a>
          </div>
        </div>
    </header>
  )
}

export default About