import React, { useEffect } from 'react'

import { ProjectsWork } from '../../container'

const Work = () => {
  useEffect(() => {
    document.title = "Randy | Projects";  
  }, []);

  return (
    <div>
        <ProjectsWork />
    </div>
  )
}

export default Work