import React, { useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { FaLinkedin, FaMediumM, FaGithub, FaDribbble, FaBehance } from 'react-icons/fa'
import { init } from 'ityped'

import './Header.css';

const Header = () => {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, { 
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,
      strings: ['self employed', 'freelance designer', 'freelance developer' ] })
  },[]);

  return (
    <header className='cont_header'>
      <div className='cont_header-content'>
          <p className='cont_header-content-head'>Hi, I'm Randy</p><br/>
          <p className='cont_header-content-p'>I am <span ref={textRef}></span>
          <br/><br/>I try to make something that makes other people&apos;s lives easier
          <br/>You can read more about me <NavLink to='/about'><u>here</u></NavLink></p><br/>
          
          <div className='cont_header-content-social'>
            <a href='https://linkedin.com/in/ransptr' target="_blank"><span><FaLinkedin /></span></a>
            <a href='https://medium.com/@ransptr' target="_blank"><span><FaMediumM /></span></a>
            <a href='https://github.com/ransptr' target="_blank"><span><FaGithub /></span></a>
            <a href='https://dribbble.com/ransptr' target="_blank"><span><FaDribbble /></span></a>
            <a href='https://behance.net/ransptr' target="_blank"><span><FaBehance /></span></a>
          </div>
        </div>
    </header>
  )
}

export default Header