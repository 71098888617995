import React, { useEffect } from 'react'

import { BackToProjects } from '../../../../components/'
import Images from '../../../../constants/Images'

import './Project3.css'

const Project3 = () => {
  useEffect(() => {
    document.title = "Randy | Project 3";  
  }, []);

  return (
    <div className='project3_content'>
        <img src={Images.ProjectHSoon} alt="Project image header" />
        <div className='project3_content-summary'>
          <h2>Project 3</h2>
          <p>Jan 2020 - Mar 2020</p>
        </div>
        <BackToProjects />
    </div>
  )
}

export default Project3