import Images from './Images';

export const FilterProjects = [
    {
      id: "all",
      title: "All",
    },
    {
      id: "web",
      title: "Website",
    },
    {
      id: "mobile",
      title: "Mobile",
    }
];

export const AllThumbnail = [
    {
        link: "/work/project-1",
        image: Images.ProjectSoon,
        title: "Project 1",
        cat: ['Live', 'Nocode', 'PWA']
    },
    {
        link: "/work/project-2",
        image: Images.ProjectSoon,
        title: "Project 2",
        cat: ['Live', 'Wordpress', 'Website']
    },
    {
        link: "/work/project-3",
        image: Images.ProjectSoon,
        title: "Project 3",
        cat: ['Concept', 'UI/UX', 'Website']
    },
    {
        link: "/work/project-4",
        image: Images.ProjectSoon,
        title: "Project 4",
        cat: ['Concept', 'UI/UX', 'Mobile']
    }
];

export const WebThumbnail = [
    {
        link: "/work/project-2",
        image: Images.ProjectSoon,
        title: "Project 2",
        cat: ['Live', 'Wordpress', 'Website']
    },
    {
        link: "/work/project-3",
        image: Images.ProjectSoon,
        title: "Project 3",
        cat: ['Concept', 'UI/UX', 'Website']
    }
];

export const MobileThumbnail = [
    {
        link: "/work/project-1",
        image: Images.ProjectSoon,
        title: "Project 1",
        cat: ['Live', 'Nocode', 'PWA']
    },
    {
        link: "/work/project-4",
        image: Images.ProjectSoon,
        title: "Project 4",
        cat: ['Concept', 'UI/UX', 'Mobile']
    }
];