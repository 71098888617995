import React from 'react'

import './ProjectsWork.css';

const ProjectsFilter = ({ id, title, active, setFilter }) => {
  return (
    <li
      className={active ? "projectsFilter active" : "projectsFilter"}
      onClick={() => setFilter(id)}
    >
      {title}
    </li>
  )
}

export default ProjectsFilter