import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Header, ProjectsHome } from '../../container/'

import './Home.css'

const Home = () => {
  useEffect(() => {
    document.title = "Randy | Home";  
  }, []);

  return (
    <div>
        <Header />

        <div className='page_home-text'>
          <p className='home-header-p'>PROJECTS</p>
          <Link to='/work'><p className='home-header-p-view'>SEE ALL →</p></Link>
        </div>

        <ProjectsHome />
    </div>
  )
}

export default Home