import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';

import { Navbar, ScrollToTop,OpenTop } from './components'; 
import { AboutMe, Home, Work } from './pages';
import { Project1, Project2, Project3, Project4 } from './pages/Work/Projects';

import './App.css';

const App = () => {
  return (
    <div>
      <Navbar />
      <div className='container'>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/work' element={<Work/>} />
        <Route path='/work/project-1' element={<Project1 />}/>
        <Route path='/work/project-2' element={<Project2 />}/>
        <Route path='/work/project-3' element={<Project3 />}/>
        <Route path='/work/project-4' element={<Project4 />}/>
        <Route path='/about' element={<AboutMe/>} />
        <Route path='*' element={<Navigate replace to="/" />} />
      </Routes>
      </div>
      <OpenTop />
      <ScrollToTop />
    </div>
  )
}

export default App