import Images from './Images';

export const HomeThumbnail = [
    {
        id: 1,
        link: "/work/project-1",
        image: Images.ProjectSoon,
        title: "Project 1",
        cat: ['Live', 'Nocode', 'PWA']
    },
    {
        id: 2,
        link: "/work/project-2",
        image: Images.ProjectSoon,
        title: "Project 2",
        cat: ['Live', 'Wordpress', 'Website']
    },
    {
        id: 3,
        link: "/work/project-3",
        image: Images.ProjectSoon,
        title: "Project 3",
        cat: ['Concept', 'UI/UX', 'Website']
    },
    {
        id: 4,
        link: "/work/project-4",
        image: Images.ProjectSoon,
        title: "Project 4",
        cat: ['Concept', 'UI/UX', 'Mobile']
    }
];