import React, { useState, useEffect } from 'react';
import {FaArrowUp} from 'react-icons/fa';

import './ScrollToTop.css';

const ScrollToTop = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
            setShowButton(true);
            } else {
            setShowButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    };

  return (
    <>
    { showButton && (
        <button className='back-to-top'><FaArrowUp onClick={scrollToTop}/></button>
    )}
    </>
  )
}

export default ScrollToTop